var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.size.col1.cols, order: _vm.size.col1.order } },
            [
              _c(
                "div",
                {
                  staticClass: "mb-6 ft-avenir announcement-text-style",
                  attrs: { id: "ac" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.$language ? "All Announcements" : "সকল নোটিশ"
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.renderNow
                ? _c(
                    "div",
                    {
                      style: `height: ${_vm.size.announcement.scroll}; overflow-y: ${_vm.size.announcement.overflow}; overflow-x: hidden`,
                    },
                    [
                      _c("dialog-delete", {
                        attrs: { "item-text": "Announcement" },
                        on: { "confirm-remove": _vm.deleteAnnouncement },
                        model: {
                          value: _vm.showDeleteDialogue,
                          callback: function ($$v) {
                            _vm.showDeleteDialogue = $$v
                          },
                          expression: "showDeleteDialogue",
                        },
                      }),
                      _vm.announcements === undefined ||
                      _vm.announcementsNew === undefined ||
                      _vm.announcements.length === 0 ||
                      _vm.announcementsNew.length === 0
                        ? _c("no-content-component", {
                            attrs: {
                              message: _vm.noContentMessage[_vm.$language],
                            },
                          })
                        : _vm._e(),
                      _vm._l(
                        _vm.isFiltered
                          ? _vm.announcements
                          : _vm.announcementsNew,
                        function (announcement, i) {
                          return _c(
                            "v-card",
                            {
                              key: i,
                              class: [
                                "announcement-card mb-5",
                                _vm.size.announcement.cardClass,
                              ],
                              staticStyle: { cursor: "pointer" },
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  class: _vm.size.announcement.rowClass,
                                  attrs: {
                                    "no-gutters":
                                      !_vm.size.announcement.rowGutter,
                                    justify: "space-between",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.announcement_details(
                                        announcement
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.size.announcement
                                          .cardHeaderCol,
                                        order:
                                          _vm.size.announcement.cardHeaderOrder,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "xl:text-base lg:text-base md:text-base sm:text-sm xs:text-sm",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(announcement.title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "xl:text-xs lg:text-xs md:text-xs sm:text-xxs xs:text-xxs",
                                          staticStyle: { color: "#a4a4a4" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? "Upload Date"
                                                  : "প্রকাশের তারিখ"
                                              ) +
                                              " : " +
                                              _vm._s(
                                                !_vm.$language
                                                  ? announcement.date_time.split(
                                                      " "
                                                    )[0]
                                                  : new Date(
                                                      announcement.date_time.split(
                                                        " "
                                                      )[0]
                                                    ).toLocaleString("bn-BD", {
                                                      dateStyle: "medium",
                                                    })
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      class:
                                        _vm.size.announcement.colShareClass,
                                      attrs: {
                                        cols: _vm.size.announcement
                                          .cardShareCol,
                                        order:
                                          _vm.size.announcement.cardShareOrder,
                                      },
                                    },
                                    [
                                      _vm.size.chip.active
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "padding-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {},
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "10px",
                                                        "border-radius": "5px",
                                                        "min-height": "28px",
                                                      },
                                                      attrs: {
                                                        color: "#FC622226",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          announcement
                                                            .course_title
                                                            .length > 10
                                                            ? announcement.course_title.substring(
                                                                0,
                                                                10
                                                              ) + "..."
                                                            : announcement.course_title
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    !_vm.$language
                                                      ? _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mt-1 mr-2 d-flex align-start",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "13px",
                                                                },
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                src: _vm.shareIcon,
                                                                alt: "",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " with " +
                                                                _vm._s(
                                                                  Object.keys(
                                                                    announcement.shared_with_batches
                                                                  ).length
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  Object.keys(
                                                                    announcement.shared_with_batches
                                                                  ).length > 1
                                                                    ? "batches"
                                                                    : "batch"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mt-1 mr-2 d-flex align-start",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "13px",
                                                                },
                                                              },
                                                              "div",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("img", {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                src: _vm.shareIcon,
                                                                alt: "",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  Object.keys(
                                                                    announcement.shared_with_batches
                                                                  ).length
                                                                ) +
                                                                " টি ব্যাচের সাথে শেয়ার করা হয়েছে "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        _vm._l(
                                          announcement.shared_with_batches,
                                          function (batch_name, j) {
                                            return _c("div", { key: j }, [
                                              _vm._v(
                                                " " + _vm._s(batch_name) + " "
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-start",
                                      attrs: {
                                        cols: _vm.size.announcement
                                          .cardActionCol,
                                        order:
                                          _vm.size.announcement.cardActionOrder,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              size: _vm.size
                                                                .announcement
                                                                .menuIconSize,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleMenuShow(
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#0089cb",
                                                    "min-height": "26px",
                                                    "max-height": "26px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.push_router_create_announcement(
                                                        announcement
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        color: "#0089cb",
                                                      },
                                                    },
                                                    [_vm._v("edit")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#0089cb",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "  " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Edit"
                                                              : "এডিট করুন"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider", {
                                                staticClass: "my-2",
                                                staticStyle: {
                                                  margin: "0px",
                                                  "background-color": "#0089cb",
                                                  opacity: "0.1",
                                                },
                                              }),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#0089cb",
                                                    "min-height": "26px",
                                                    "max-height": "26px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showDeleteDialogue = true
                                                      _vm.announcementIdToDelete =
                                                        announcement.announcement_id
                                                      _vm.courseIdToDeleteFrom =
                                                        announcement.course_id
                                                      _vm.partner_code =
                                                        announcement.partner_code
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        color: "#0089cb",
                                                      },
                                                    },
                                                    [_vm._v("delete")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "#0089cb",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "  " +
                                                          _vm._s(
                                                            !_vm.$language
                                                              ? "Delete"
                                                              : "ডিলিট করুন"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  class: _vm.size.announcement.contentClass,
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#5b5c5e",
                                    height: "64%",
                                  },
                                  attrs: {
                                    "no-gutters":
                                      !_vm.size.announcement.rowGutter,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.announcement_details(
                                        announcement
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-col", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        announcement.text
                                          .wrap(280)
                                          .replace(/<a.*?>.*?<\/a>/gi, "")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: { color: "primary", indeterminate: "" },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.size.col2.class,
              attrs: { cols: _vm.size.col2.cols, order: _vm.size.col2.order },
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "border-right": "6px", "box-shadow": "none" },
                  attrs: {
                    color: "#0099DC",
                    dark: "",
                    height: _vm.size.create.height,
                    width: _vm.size.create.width,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/teachers/create-announcement")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { size: "30" } }, [_vm._v("mdi-plus")]),
                  !_vm.$language
                    ? _c("div", { staticStyle: { "margin-left": "12px" } }, [
                        _vm._v(" Create Announcement "),
                      ])
                    : _c("div", { staticStyle: { "margin-left": "12px" } }, [
                        _vm._v("নোটিশ তৈরী করুন"),
                      ]),
                ],
                1
              ),
              _c("br"),
              !_vm.$language
                ? _c(
                    "v-row",
                    {
                      staticClass: "ft-avenir py-4",
                      staticStyle: {
                        "font-size": "16px",
                        "letter-spaceing": "1px",
                        "font-weight": "600",
                      },
                      attrs: { "no-gutters": "" },
                    },
                    [_vm._v("Announcement Filter")]
                  )
                : _c(
                    "v-row",
                    {
                      staticClass: "ft-avenir py-4",
                      staticStyle: {
                        "font-size": "16px",
                        "letter-spaceing": "1px",
                        "font-weight": "600",
                      },
                      attrs: { "no-gutters": "" },
                    },
                    [_vm._v("নোটিশ ফিল্টার করুন")]
                  ),
              _c(
                "v-card",
                {
                  staticClass: "px-6 py-4",
                  attrs: { flat: "", outlined: "", "max-width": "265" },
                },
                [
                  _c("submission-alert-message", {
                    ref: "alertMessage",
                    attrs: { message: _vm.badSubmissionMessage[_vm.$language] },
                    model: {
                      value: _vm.showGeneralMessage,
                      callback: function ($$v) {
                        _vm.showGeneralMessage = $$v
                      },
                      expression: "showGeneralMessage",
                    },
                  }),
                  !_vm.$language
                    ? _c(
                        "div",
                        {
                          staticClass: "ft-avenir mt-2",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#202020",
                            "letter-spaceing": "1px",
                          },
                        },
                        [_vm._v(" Filter Date From ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "ft-avenir mt-2",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#202020",
                            "letter-spaceing": "1px",
                          },
                        },
                        [_vm._v(" এই তারিখ থেকে ")]
                      ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mt-2",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            outlined: "",
                                            clearable: "",
                                            dense: "",
                                            readonly: "",
                                            "hide-details": "",
                                            label: !_vm.$language
                                              ? "Enter a date"
                                              : " তারিখ লিখুন",
                                            "append-icon": "mdi-calendar",
                                          },
                                          on: {
                                            "click:clear": function ($event) {
                                              return _vm.onClearDate()
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedDate,
                                            callback: function ($$v) {
                                              _vm.selectedDate = $$v
                                            },
                                            expression: "selectedDate",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-calendar")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.shareDateMenu,
                            callback: function ($$v) {
                              _vm.shareDateMenu = $$v
                            },
                            expression: "shareDateMenu",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            attrs: { "no-title": "", scrollable: "" },
                            on: {
                              input: function ($event) {
                                _vm.shareDateMenu = false
                              },
                            },
                            model: {
                              value: _vm.selectedDate,
                              callback: function ($$v) {
                                _vm.selectedDate = $$v
                              },
                              expression: "selectedDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.$language
                    ? _c(
                        "div",
                        {
                          staticClass: "ft-avenir mt-5",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#202020",
                            "letter-spaceing": "1px",
                          },
                        },
                        [_vm._v(" Shared Batches ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "ft-avenir mt-5",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#202020",
                            "letter-spaceing": "1px",
                          },
                        },
                        [_vm._v(" যেসব ব্যাচের সাথে শেয়ারকৃত ")]
                      ),
                  _c("v-combobox", {
                    staticClass: "mt-2",
                    staticStyle: { width: "100%" },
                    attrs: {
                      outlined: "",
                      dense: "",
                      clearable: "",
                      "hide-details": "",
                      label: !_vm.$language
                        ? "Select a batch"
                        : "ব্যাচ সিলেক্ট করুন",
                      items: Object.values(_vm.batchIdVsName),
                    },
                    on: {
                      "click:clear": function ($event) {
                        return _vm.onClearBatch()
                      },
                    },
                    model: {
                      value: _vm.selectedBatch,
                      callback: function ($$v) {
                        _vm.selectedBatch = $$v
                      },
                      expression: "selectedBatch",
                    },
                  }),
                  !_vm.$language
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-8 white--text ft-avenir",
                          staticStyle: { width: "100%", "font-weight": "600" },
                          attrs: {
                            color: "#0099DC",
                            height: _vm.size.button.height,
                          },
                          on: { click: _vm.searchAnnouncements },
                        },
                        [_vm._v("Search")]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "mt-8 white--text ft-avenir",
                          staticStyle: { width: "100%", "font-weight": "600" },
                          attrs: {
                            color: "#0099DC",
                            height: _vm.size.button.height,
                          },
                          on: { click: _vm.searchAnnouncements },
                        },
                        [_vm._v("সার্চ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }