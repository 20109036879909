import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "announcements/",
  get_comments(announcement_id, course_id, batch_id, opts = {}) {
    return $backend
      .post("teachers_get_comments", {
        announcement_id: announcement_id,
        course_id: course_id,
        batch_id: batch_id
      })
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  post_comments(
    comment_id = undefined,
    announcement_id,
    course_id,
    batch_id,
    partner_code,
    text,
    opts = {}
  ) {
    let data = {
      announcement_id: announcement_id,
      course_id: course_id,
      batch_id: batch_id,
      partner_code: partner_code,
      text: text
    };
    if (comment_id !== undefined) {
      data["comment_id"] = comment_id;
    }
    return $backend
      .post("teachers_post_comments", data, { shouldSendPartnerCode: false })
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  delete_comments(comment_id, announcement_id) {
    return $backend
      .post("teachers_delete_comments", {
        comment_id: comment_id,
        announcement_id: announcement_id
      })
      .then(checkStatus)
      .then(r => r.data.content.comments);
  },
  get(announcement_id, course_id, partner_code, opts = {}) {
    return $backend
      .post(
        "teachers_get_announcement",
        {
          announcement_id: announcement_id,
          course_id: course_id,
          partner_code: partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(r => r.data.content.announcement);
  },
  /**
   * @return Promise<AxiosResponse>
   * @param courseId
   * @param batchId
   * @param announcementText
   * @param opts
   */
  list(
    last_fetch_time = undefined,
    batch_ids = undefined,
    selected_date = undefined,
    opts = {}
  ) {
    let data = { last_fetch_time: last_fetch_time };
    if (batch_ids !== []) {
      data["batch_ids"] = batch_ids;
    }
    if (selected_date !== undefined) {
      data["selected_date"] = selected_date;
    }
    // todo maybe do some transformation. Not needed for now
    return $backend
      .post("teachers_get_announcements_optimized", data, {
        shouldSendPartnerCode: false
      })
      .then(checkStatus)
      .then(r => r.data.content);
  },
  /**
   * @return Promise
   * @param courseId
   * @param sharedWithBatches
   * @param announcerId
   * @param announcementText
   * @param title
   * @param text
   * @param headers
   */
  save(
    courseId,
    sharedWithBatches,
    announcerId,
    title,
    text,
    announcementId = undefined,
    partner_code,
    headers = { shouldSendPartnerCode: false }
  ) {
    let data = {
      course_id: courseId,
      shared_with_batches: sharedWithBatches,
      announcer_id: announcerId,
      title: title,
      text: text,
      partner_code: partner_code
    };
    if (announcementId !== undefined) {
      data.announcement_id = announcementId;
    }
    return $backend
      .post("teachers_post_announcements", data, headers)
      .then(checkStatus)
      .then(r => r.data.content);
  },
  delete(announcement_id, course_id, partner_code, opts = {}) {
    return $backend
      .post(
        "teachers_delete_announcement",
        {
          announcement_id: announcement_id,
          course_id: course_id,
          partner_code: partner_code
        },
        { shouldSendPartnerCode: false }
      )
      .then(checkStatus)
      .then(r => r.data.content.announcement);
  }
};
