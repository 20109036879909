<template>
  <v-container>
    <v-row>
      <v-col :cols="size.col1.cols" :order="size.col1.order">
        <div id="ac" class="mb-6 ft-avenir announcement-text-style">
          {{ !$language ? "All Announcements" : "সকল নোটিশ" }}
        </div>
        <div
          v-if="renderNow"
          :style="
            `height: ${size.announcement.scroll}; overflow-y: ${size.announcement.overflow}; overflow-x: hidden`
          "
        >
          <dialog-delete
            v-model="showDeleteDialogue"
            item-text="Announcement"
            @confirm-remove="deleteAnnouncement"
          ></dialog-delete>
          <no-content-component v-if="announcements === undefined || announcementsNew === undefined|| announcements.length === 0 || announcementsNew.length === 0"
            :message="noContentMessage[$language]"
          ></no-content-component>
          <v-card
            v-for="(announcement, i) in isFiltered
              ? announcements
              : announcementsNew"
            :key="i"
            flat
            :class="['announcement-card mb-5', size.announcement.cardClass]"
            style="cursor: pointer"
          >
            <v-row
              :no-gutters="!size.announcement.rowGutter"
              :class="size.announcement.rowClass"
              justify="space-between"
              @click="announcement_details(announcement)"
            >
              <v-col
                :cols="size.announcement.cardHeaderCol"
                :order="size.announcement.cardHeaderOrder"
              >
                <div
                  class="xl:text-base lg:text-base md:text-base sm:text-sm xs:text-sm"
                >
                  {{ announcement.title }}
                </div>
                <div
                  style="color: #a4a4a4"
                  class="xl:text-xs lg:text-xs md:text-xs sm:text-xxs xs:text-xxs"
                >
                  {{ !$language ? "Upload Date" : "প্রকাশের তারিখ" }} :
                  {{
                    !$language
                      ? announcement.date_time.split(" ")[0]
                      : new Date(
                          announcement.date_time.split(" ")[0]
                        ).toLocaleString("bn-BD", { dateStyle: "medium" })
                  }}
                </div>
              </v-col>
              <v-col
                :class="size.announcement.colShareClass"
                :cols="size.announcement.cardShareCol"
                :order="size.announcement.cardShareOrder"
              >
                <div
                  v-if="size.chip.active"
                  style="display: flex; padding-right: 10px"
                >
                  <div style="">
                    <v-chip
                      style="
                        font-size: 10px;
                        border-radius: 5px;
                        min-height: 28px;
                      "
                      color="#FC622226"
                      >{{
                        announcement.course_title.length > 10
                          ? announcement.course_title.substring(0, 10) + "..."
                          : announcement.course_title
                      }}</v-chip
                    >
                  </div>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-if="!$language"
                      style="font-size: 13px"
                      v-bind="attrs"
                      class="mt-1 mr-2 d-flex align-start"
                      v-on="on"
                    >
                      <!-- <v-icon size="18" color="#0099DC">share</v-icon> -->
                      <img :src="shareIcon" class="mr-2" alt="" />
                      with
                      {{ Object.keys(announcement.shared_with_batches).length }}
                      {{
                        Object.keys(announcement.shared_with_batches).length > 1
                          ? "batches"
                          : "batch"
                      }}
                    </div>
                    <div
                      v-else
                      style="font-size: 13px"
                      v-bind="attrs"
                      class="mt-1 mr-2 d-flex align-start"
                      v-on="on"
                    >
                      <img :src="shareIcon" class="mr-2" alt="" />
                      {{ Object.keys(announcement.shared_with_batches).length }}
                      টি ব্যাচের সাথে শেয়ার করা হয়েছে
                    </div>
                  </template>
                  <div
                    v-for="(batch_name, j) in announcement.shared_with_batches"
                    :key="j"
                  >
                    {{ batch_name }}
                  </div>
                </v-tooltip>
                <!-- <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="28"
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleMenuShow(i)"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      style="
                        font-size: 12px;
                        color: #0089cb;
                        min-height: 26px;
                        max-height: 26px;
                      "
                      @click="
                        $router.push({
                          name: 'create-announcement',
                          params: {
                            announcement: announcement,
                          },
                        })
                      "
                    >
                      <v-icon style="color: #0089cb">edit</v-icon>
                      <div style="color: #0089cb">&nbsp;Edit</div>
                    </v-list-item>
                    <v-divider
                      style="
                        margin: 0px;
                        background-color: #0089cb;
                        opacity: 0.1;
                      "
                      class="my-2"
                    ></v-divider>
                    <v-list-item
                      style="
                        font-size: 12px;
                        color: #0089cb;
                        min-height: 26px;
                        max-height: 26px;
                      "
                      @click="
                        showDeleteDialogue = true;
                        announcementIdToDelete = announcement.announcement_id;
                        courseIdToDeleteFrom = announcement.course_id;
                      "
                    >
                      <v-icon style="color: #0089cb">delete</v-icon>
                      <div style="color: #0089cb">&nbsp;Delete</div>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </v-col>
              <v-col
                :cols="size.announcement.cardActionCol"
                :order="size.announcement.cardActionOrder"
                class="d-flex justify-center align-start"
              >
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :size="size.announcement.menuIconSize"
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleMenuShow(i)"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      style="
                        font-size: 12px;
                        color: #0089cb;
                        min-height: 26px;
                        max-height: 26px;
                      "
                      @click="push_router_create_announcement(announcement)"
                    >
                      <v-icon style="color: #0089cb">edit</v-icon>
                      <div style="color: #0089cb">
                        &nbsp;{{ !$language ? "Edit" : "এডিট করুন" }}
                      </div>
                    </v-list-item>
                    <v-divider
                      style="
                        margin: 0px;
                        background-color: #0089cb;
                        opacity: 0.1;
                      "
                      class="my-2"
                    ></v-divider>
                    <v-list-item
                      style="
                        font-size: 12px;
                        color: #0089cb;
                        min-height: 26px;
                        max-height: 26px;
                      "
                      @click="
                        showDeleteDialogue = true;
                        announcementIdToDelete = announcement.announcement_id;
                        courseIdToDeleteFrom = announcement.course_id;
                        partner_code = announcement.partner_code;
                      "
                    >
                      <v-icon style="color: #0089cb">delete</v-icon>
                      <div style="color: #0089cb">
                        &nbsp;{{ !$language ? "Delete" : "ডিলিট করুন" }}
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              :no-gutters="!size.announcement.rowGutter"
              style="font-size: 12px; color: #5b5c5e; height: 64%"
              :class="size.announcement.contentClass"
              @click="announcement_details(announcement)"
            >
              <v-col
                v-html="
                  announcement.text.wrap(280).replace(/<a.*?>.*?<\/a>/gi, '')
                "
              />
            </v-row>
          </v-card>
        </div>
        <div v-else class="d-flex justify-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col
        :class="size.col2.class"
        :cols="size.col2.cols"
        :order="size.col2.order"
      >
        <v-btn
          color="#0099DC"
          dark
          :height="size.create.height"
          :width="size.create.width"
          style="border-right: 6px; box-shadow: none"
          @click="$router.push('/teachers/create-announcement')"
        >
          <v-icon size="30">mdi-plus</v-icon>
          <div v-if="!$language" style="margin-left: 12px">
            Create Announcement
          </div>
          <div v-else style="margin-left: 12px">নোটিশ তৈরী করুন</div></v-btn
        ><br />
        <v-row
          v-if="!$language"
          no-gutters
          class="ft-avenir py-4"
          style="font-size: 16px; letter-spaceing: 1px; font-weight: 600"
          >Announcement Filter</v-row
        >
        <v-row
          v-else
          no-gutters
          class="ft-avenir py-4"
          style="font-size: 16px; letter-spaceing: 1px; font-weight: 600"
          >নোটিশ ফিল্টার করুন</v-row
        >
        <v-card flat outlined max-width="265" class="px-6 py-4">
          <submission-alert-message v-model="showGeneralMessage"
            :message="badSubmissionMessage[$language]"
            ref="alertMessage"
          ></submission-alert-message>
          <div
            v-if="!$language"
            style="font-size: 14px; color: #202020; letter-spaceing: 1px"
            class="ft-avenir mt-2"
          >
            Filter Date From
          </div>
          <div
            v-else
            style="font-size: 14px; color: #202020; letter-spaceing: 1px"
            class="ft-avenir mt-2"
          >
            এই তারিখ থেকে
          </div>
          <v-row no-gutters>
            <v-menu
              v-model="shareDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  outlined
                  clearable
                  @click:clear="onClearDate()"
                  dense
                  readonly
                  hide-details
                  style="width: 100%"
                  class="mt-2"
                  :label="!$language ? 'Enter a date' : ' তারিখ লিখুন'"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                no-title
                scrollable
                @input="shareDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-row>

          <div
            v-if="!$language"
            style="font-size: 14px; color: #202020; letter-spaceing: 1px"
            class="ft-avenir mt-5"
          >
            Shared Batches
          </div>
          <div
            v-else
            style="font-size: 14px; color: #202020; letter-spaceing: 1px"
            class="ft-avenir mt-5"
          >
            যেসব ব্যাচের সাথে শেয়ারকৃত
          </div>
          <v-combobox
            v-model="selectedBatch"
            outlined
            dense
            clearable
            @click:clear="onClearBatch()"
            style="width: 100%"
            class="mt-2"
            hide-details
            :label="!$language ? 'Select a batch' : 'ব্যাচ সিলেক্ট করুন'"
            :items="Object.values(batchIdVsName)"
          >
          </v-combobox>
          <v-btn
            v-if="!$language"
            color="#0099DC"
            class="mt-8 white--text ft-avenir"
            style="width: 100%; font-weight: 600"
            :height="size.button.height"
            @click="searchAnnouncements"
            >Search</v-btn
          >
          <v-btn
            v-else
            color="#0099DC"
            class="mt-8 white--text ft-avenir"
            style="width: 100%; font-weight: 600"
            :height="size.button.height"
            @click="searchAnnouncements"
            >সার্চ</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import announcementService from "#ecf/announcement/services/AnnouncementService";
import { getOwnCourses, getOrgCourses } from "#ecf/courses/actions";
import DialogDelete from "@ecf/global/components/DialogDelete";
import { mapState, mapGetters } from "vuex";
import SubmissionAlertMessage from '/global/components/SubmissionAlertMessage.vue';
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "Announcements",
  components: {
    DialogDelete,
    SubmissionAlertMessage,
    NoContentComponent
  },
  data() {
    return {
      selectedDate: undefined,
      last_fetch_time: this.$store.state.announcement.last_fetch_time,
      renderNow: false,
      course_ids_and_partner_codes: [],
      course_ids_vs_partner_codes: {},
      batchIdVsCourseId: {},
      selectedBatch: undefined,
      selectedBatches: [],
      shareDateMenu: false,
      announcements: [],
      announcementToDelete: undefined,
      courseIdToDeleteFrom: undefined,
      partner_code: undefined,
      partnerCodeToDeleteFrom: undefined,
      showEditDeleteMenu: [],
      showDeleteDialogue: false,
      isFiltered: false,
      showGeneralMessage: false,
    };
  },
  computed: {
    badSubmissionMessage() {
      return {
        true: "ব্যাচ অথবা তারিখ সিলেক্ট করুন",
        false:
          "Select batch or date",
      };
    },
    noContentMessage(){
      return {
        true: "কোনো অ্যানাউন্সমেন্ট পাওয়া যায় নি",
        false: "No announcement found"
      }
    },
    ...mapGetters({ announcementsNew: "announcement/allAnnouncements" }),
    shareIcon() {
      return require("#ecf/announcement/assets/icons/Group 18344.svg");
    },
    batchIdVsName() {
      return this.announcementsNew.reduce((result, item) => {
        if (item.shared_with_batches) {
          Object.assign(result, item.shared_with_batches);
        }
        return result;
      }, {});
    },
    size() {
      let bp = this.$vuetify.breakpoint.width;
      let bh = this.$vuetify.breakpoint.height;
      return {
        button: {
          height: bp < 960 ? "32" : "45"
        },
        announcement: {
          scroll: bp < 960 ? "auto" : `${bh - 180}px`,
          overflow: bp < 960 ? "visible" : "auto",
          cardHeaderCol: bp < 600 ? "10" : "6",
          cardShareCol: bp < 600 ? "12" : "5",
          cardActionCol: bp < 600 ? "2" : "1",
          cardHeaderOrder: 1,
          cardShareOrder: bp < 600 ? 3 : 2,
          cardActionOrder: bp < 600 ? 2 : 3,
          rowGutter: bp < 960 ? false : true,
          rowClass: bp < 960 ? "pt-2 pb-1 pl-3 pr-0" : "pt-1 pl-6",
          colShareClass:
            bp < 600 ? "d-flex justify-start pt-1" : "d-flex justify-end",
          contentClass: bp < 960 ? "pb-2 pt-4 px-3" : "px-6",
          cardClass: bp < 600 ? "pa-1" : bp < 960 ? "pa-2" : "",
          menuIconSize: bp < 960 ? "20" : "28"
        },
        col1: {
          cols: bp < 960 ? "12" : "8",
          order: bp < 960 ? 2 : 1
        },
        col2: {
          class: bp < 960 ? "" : "ml-2",
          cols: bp < 960 ? "12" : "",
          order: bp < 960 ? 1 : 2
        },
        create: {
          width: bp < 600 ? "256" : "265",
          height: bp < 960 ? "35" : "45"
        },
        chip: {
          active: bp < 960 ? false : true
        }
      };
    }
  },
  watch:{
    selectedBatch:{
      handler (value) {
        if(value !== undefined || value !== null){
          this.showGeneralMessage = false;
        }
      }
    },
    selectedDate: {
      handler (value) {
         if(value !== undefined || value !== null){
          this.showGeneralMessage = false;
        }
      }
    }
  },
  async created() {
    await this.fetchAnnouncements();
  },
  methods: {
    closeMessage(){
      this.showGeneralMessage = false;
    },
    push_router_create_announcement(announcement) {
      this.$router.push({
        name: "create-announcement",
        params: {
          announcement: announcement
        }
      });
    },
    announcement_details(announcement) {
      this.$router.push({
        name: "announcement-details",
        params: {
          announcement_id: announcement.announcement_id,
          course_id: announcement.course_id,
          partner_code: announcement.partner_code
        }
      });
    },
    async fetchAnnouncements() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"]);
        return;
      }
      try {
        let content = await announcementService.list(
          this.last_fetch_time,
          undefined,
          undefined
        );
        if (content.updated === true && content.announcements !== undefined) {
          this.announcements = content.announcements;
          this.last_fetch_time = content.last_fetch_time;
          await this.$store.dispatch("announcement/setAnnouncements", {
            announcements: this.announcements,
            last_fetch_time: this.last_fetch_time
          });
        } else this.announcements = await this.announcementsNew;
        for (let i = 0; i < this.announcements.length; i++) {
          this.showEditDeleteMenu.push(false);
        }
        this.renderNow = true;
      } catch (e) {
        this.renderNow = false;
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async deleteAnnouncement() {
      this.showDeleteDialogue = true;
      try {
        this.announcements = await announcementService.delete(
          this.announcementIdToDelete,
          this.courseIdToDeleteFrom,
          this.partner_code
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
      this.showDeleteDialogue = false;
      await this.fetchAnnouncements();
    },
    toggleMenuShow(index) {
      this.showEditDeleteMenu[index] = !this.showEditDeleteMenu[index];
      this.$forceUpdate();
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    onClearBatch() {
      this.selectedBatch = undefined;
    },
    onClearDate() {
      this.selectedDate = undefined;
    },
    async searchAnnouncements() {
      this.renderNow = false;
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"]);
        return;
      }
      if (this.selectedBatch === null) this.selectedBatch = undefined;
      else if (this.selectedDate === null) this.selectedDate = undefined;
      if ((this.selectedBatch === undefined && this.selectedDate === undefined)) {
        if( this.showGeneralMessage){
          this.$refs.alertMessage.flash();
        }
        this.showGeneralMessage = true;
        this.renderNow = true;
        return;
      } else {
        let selectedBatchIds = [];
        if (this.selectedBatch !== undefined) {
          let batchId = this.getKeyByValue(
            this.batchIdVsName,
            this.selectedBatch
          );
          if (batchId !== undefined) {
            selectedBatchIds.push(batchId);
          }
        }
        this.isFiltered = true;
        const content = await announcementService.list(
          undefined,
          selectedBatchIds,
          this.selectedDate
        );
        this.announcements = content.announcements;
        this.renderNow = true;
      }
    }
  }
};
</script>

<style>
.v-input .v-label {
  font-size: 12px !important;
}
.v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
.v-chip {
  overflow: initial;
  max-height: 20px;
}
</style>

<style scoped lang="scss">
.course-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;

  background: #0099dc1a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.batch-label {
  padding-top: 6px;
  height: 30px;
  text-align: center;
  justify-content: center;
  background: #fc62221a 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001a;
  border-radius: 5px;
  opacity: 1;
}

.announcement-text-style {
  font-size: 20px;
  @media screen and (max-width: 959px) {
    font-size: 16px;
    letter-spaceing: 1px;
    font-weight: 600;
  }
}
</style>
